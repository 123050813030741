html, body{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  height: 100%;
}

@keyframes loadingIndicator {
  0% { top: 0; left: 0; }
  25% { top: 0; left: 96px; }
  50% { top: 96px; left: 96px; }
  75% { top: 96px; left: 0; }
  100% { top: 0; left: 0; }
}